import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceData } from "../../types/service";
import ServicesSection from "../../components/composites/ServicesSection";

const Services = () => {
  const [serviceLimit, setServiceLimit] = React.useState<number>(6);
  const [servicesRecord, setServicesRecord] = useState<ServiceData>();

  async function fetchData() {
    const msg = `An error while retrieving services content!`;
    try {
      const params = {
        "filters[visibleOnHomePage][$eq]": true,
        "populate": "blocks,images,logo",
        //"pagination[limit]": serviceLimit,
        "sort": "id:desc"
      };
      const { status, data } = await api("/api/services", "get", params);
      if (status >= 200 && status <= 299) {
        setServicesRecord(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ServicesSection servicesRecord={servicesRecord} />
  );
};

export default Services;